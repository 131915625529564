<template>
    <dashboard-layout>
        <div class="content-area__body">
            <!-- account section -->
            <section class="account-section section-gap-30">
                <div class="secondary-sidebar">
                    <div class="secondary-sidebar-heading gutter-10" :class="{'hamburger-button-active' : showMenu}" @click="showMenu = !showMenu">
                        <div class="section-title">{{ $t("My Account")}}</div>
                    </div>
                    <div class="secondary-sidebar-body gutter-10" :class="{'hamburger-menu-show': showMenu}">
                        <ul class="account__nav"> <!--v-on-click-away="hideMenu"-->
                            <li class="account__nav__item">
                                <router-link :to="{name : 'my-account.information'}" class="account__nav__link" exact exact-active-class="active">{{ $t("account information")}}</router-link>
                            </li>
                            <li class="account__nav__item" v-if="company">
                                <router-link :to="{name : 'my-account'}" class="account__nav__link" exact exact-active-class="active">{{ $t("Subscription")}}</router-link>
                            </li>
                            <li class="account__nav__item" v-if="company">
                                <router-link :to="{name : 'my-account.billing'}" class="account__nav__link" exact exact-active-class="active">{{ $t("Billing")}}</router-link>
                            </li>
                            <li class="account__nav__item" v-if="company">
                                <router-link :to="{name : 'my-account.payment-history'}" class="account__nav__link" exact exact-active-class="active">{{ $t("Payment History")}}</router-link>
                            </li>
                        </ul>
                        <a href="#" class="button danger-button w-100" @click.prevent="deleteMyAccount">{{ $t("Delete Account")}}</a>
                    </div>
                </div>
                <router-view></router-view>
            </section>
        </div>
    </dashboard-layout>
</template>
<script>
    import DashboardLayout from "./DashboardLayout";
    import client from "../app/api/Client";
    import {mapState} from "vuex";

    export default {
        components: {
            DashboardLayout,
        },
        data() {
            return {
                showMenu: false
            }
        },
        computed: {
            ...mapState(['company']),

        },
        methods: {
            hideMenu() {
                this.showMenu = false;
            },
            deleteMyAccount() {
                try {
                    let message = {
                        title: this.$t('confirmation'),
                        body: `<div class="custom-delete">
                                <p class="text-center">${this.$t('If you delete this account, following things will also be permanently deleted:')}</p>
                                <ul class="small-text text-left" style="font-size: 13px;">
                                    <li>-${this.$t('Account information along with your profile, resume, applied & unfinished jobs.')}</li>
                                    <li>-${this.$t('All companies with logo & images.')}</li>
                                    <li>-${this.$t('All contributors/users.')}</li>
                                    <li>-${this.$t('All jobs & candidates information.')}</li>
                                    <li>-${this.$t('All question set question & quizzes.')}</li>
                                    <li>-${this.$t('All pipelines.')}</li>
                                </ul>
                         </div>`,
                    };

                    this.$dialog.confirm(message, {
                        html: true,
                        verification: 'delete',
                        type: 'hard'
                    }).then(() => {
                        client().delete(`/my-account`).then(({data}) => {
                            if (data.status === 'SUCCESS') {
                                this.$router.push({'name': 'logout'});
                                this.$toast.success(data.message);
                            } else {
                                this.$toast.error(data.message);
                            }
                        });

                    });
                } catch (e) {
                }
            }
        },
        watch:{
            $route (to, from){
                this.hideMenu();
            }
        }
    }
</script>

<style scoped>
    .dg-content {
        text-align: left !important;
    }
    .dg-content-cont--floating {
        top:40%!important;
        transform: translateY(-50%);

    }
    .small-text {
        font-size: 13px;
    }
    .secondary-sidebar .account__nav {
        list-style: none;
    }
</style>
